/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */


html, body {
    font-size: 13px;
    color: #4F5253;
    padding: 0 !important;
    margin: 0 !important;
}

input { -webkit-user-select:text;}

*::-webkit-scrollbar {
    -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
    width: 12px;
}

*::-webkit-scrollbar:horizontal {
    height: 12px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}

*::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}

.Toastify__toast--success {
    background-color: #EEF8EF !important;
}

.Toastify__toast--warning {
    background-color: #f7f4ed !important;
}

.Toastify__toast--error {
    background-color: #f7eded !important;
}

.Toastify__toast-container {
    display: flex !important;
    justify-content: center !important;
    margin-top: 8px !important;
}

.Toastify__toast {
    min-height: 36px !important;
    border-radius: 36px !important;
    padding: 8px 16px !important;
    box-shadow: unset !important;
    width: 400px !important;
}

.Toastify__toast--success .Toastify__toast-body, .Toastify__toast--success .Toastify__close-button {
    color: #39C173 !important;
}

.Toastify__toast--warning .Toastify__toast-body, .Toastify__toast--warning .Toastify__close-button {
    color: #bf8e39 !important;
}

.Toastify__toast--error .Toastify__toast-body, .Toastify__toast--error .Toastify__close-button {
    color: #bf3939 !important;
}

#onesignal-slidedown-container #onesignal-slidedown-dialog #onesignal-slidedown-cancel-button {
    font-weight: 400 !important;
    color: #D25525 !important;
    padding: 8px 12px !important;
    font-size: 1rem !important;
}

#onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-button.primary {
    text-transform: capitalize !important;
    font-weight: 400 !important;
    background-color: #D25525 !important;
    border-radius: 16px !important;
    box-shadow: unset !important;
    padding: 8px 12px !important;
    font-size: 1rem !important;
}

@media (max-width: 600px) {
    #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-footer {
        margin-top: 12px !important;
    }

    #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body-icon {
        width: 60px !important;
        height: 60px !important;
    }

    #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body-message {
        width: calc(100% - 60px) !important;
    }
}